import { t } from 'i18next';

import { formatDate } from '@/utils';

import DataTableColumnHeader from
  '@/components/DataTable/DataTableColumnHeader';
import DataTableFacetedFilter from
  '@/components/DataTable/DataTableFacetedFilter';
import TooltipCell from '@/components/DataTable/TooltipCell';

// For GSD and GSO event types are between -1 and 17;
const eventTypes = Array.from(new Array(18), (_, i) => (i - 1));

const getLabelForEventType = (eventType) => t(`stream.eventType${eventType}`);

const DataColumns = [
  {
    accessorKey: 'eventType',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={t('stream.eventType')} />
    ),
    cell: ({ row }) => (
      <div className="flex items-center">
        {getLabelForEventType(row.getValue('eventType'))}
      </div>
    ),
    size: 200,
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
    filterUI: (table) => (
      <DataTableFacetedFilter
        column={table.getColumn('eventType')}
        title={t('stream.eventType')}
        options={eventTypes.map((eventType) => ({
          value: eventType,
          label: getLabelForEventType(eventType),
        }))}
      />
    ),
  },
  {
    accessorKey: 'eventTimestamp',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t('stream.eventTimestamp')}
      />
    ),
    size: 300,
    cell: ({ row }) => (
      <div className="flex items-center">
        {formatDate(row.getValue('eventTimestamp'))}
      </div>
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
  {
    accessorKey: 'data',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t('stream.data')}
      />
    ),
    size: 600,
    cell: ({ row }) => (
      <TooltipCell value={row.getValue('data')} />
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
];

export default DataColumns;
