import { t } from 'i18next';
import { formatDate } from '@/utils';
import { cn } from '@/lib/utils';

import {
  CrossCircledIcon,
  CheckCircledIcon,
  QuestionMarkCircledIcon,
} from '@radix-ui/react-icons';

import { Input } from '@/components/ui/input';

import DataTableFacetedFilter from
  '@/components/DataTable/DataTableFacetedFilter';
import DataTableColumnHeader from
  '@/components/DataTable/DataTableColumnHeader';

const outcomes = [
  {
    icon: CheckCircledIcon,
    style: 'text-green',
    value: 'accepted',
  },
  {
    icon: CrossCircledIcon,
    style: 'text-red',
    value: 'rejected',
  },
  {
    icon: QuestionMarkCircledIcon,
    value: 'noData',
  },
];

const getLabel = (value) => {
  switch (value) {
    case 'accepted':
      return t('verificationResults.acceptedOutcome');
    case 'rejected':
      return t('verificationResults.rejectedOutcome');
    case 'noData':
      return t('verificationResults.noDataOutcome');
    default:
      return '';
  }
};

const columns = [
  {
    accessorKey: 'outcome',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t('verificationResults.outcome')}
      />
    ),
    size: 180,
    cell: ({ row }) => {
      const outcome = outcomes.find((o) => o.value === row.getValue('outcome'));
      if (!outcome) {
        return null;
      }
      return (
        <div className="flex items-center">
          {outcome.icon && (
            <outcome.icon
              className={cn(
                'mr-2 h-4 w-4',
                outcome.style ?? 'text-muted-foreground',
              )}
            />
          )}
          <span>{getLabel(outcome.value)}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
    filterUI: (table) => (
      <DataTableFacetedFilter
        column={table.getColumn('outcome')}
        title={t('verificationResults.outcome')}
        options={outcomes.map((outcome) => ({
          ...outcome,
          label: getLabel(outcome.value),
        }))}
      />
    ),
  },
  {
    accessorKey: 'score',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t('verificationResults.score')}
      />
    ),
    size: 150,
    cell: ({ row }) => (
      <div className="flex items-center">
        {row.getValue('score')}
      </div>
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
  {
    accessorKey: 'streamId',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t('verificationResults.streamId')}
      />
    ),
    size: 300,
    cell: ({ row }) => (
      <div className="flex items-center">
        {row.getValue('streamId') ?? ''}
      </div>
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
    filterUI: (table) => (
      <Input
        placeholder={t('verificationResults.filterByStreamId')}
        value={(table.getColumn('streamId')?.getFilterValue()) ?? ''}
        onChange={(event) => table
          .getColumn('streamId')?.setFilterValue(event.target.value)}
        className="h-8 w-[150px] lg:w-[250px]"
      />
    ),
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t('verificationResults.createdAt')}
      />
    ),
    size: 180,
    cell: ({ row }) => (
      <div className="flex w-[300px] items-center">
        {formatDate(row.getValue('createdAt'))}
      </div>
    ),
    filterFn: (row, id, value) => value.includes(row.getValue(id)),
  },
];

export { outcomes, getLabel };
export default columns;
